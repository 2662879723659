<template>
	<!-- 认证中心 -->
	<div class="storeCert el-content">
		<a-button class="mb12" v-has="{action:'/adhibition/store/cert_edit'}" @click="show.template = true" type="primary">
			<i class="ri-add-line ri-lg ri-top"></i>
			新增认证
		</a-button>
		<a-table :pagination="false" row-key="cert_id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'cert_id'},
			{title:'认证类型',dataIndex:'cert_name'},
			{title:'认证价格(元)',dataIndex:'cert_amount',slots:{customRender:'cert_amount'}},
			{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'创建时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]">
			<template #cert_amount="{record}">
				<span v-if="record.cert_amount > 0">￥{{record.cert_amount}}</span>
				<span v-else>免费</span>
			</template>
			<template #status="{record}">
				<a-tag :color="record.status == 1 ?'#00CC66':'#999'">{{record.status == 1 ? '启用':'禁用'}}</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/adhibition/store/cert_edit',query:{cert_id:record.cert_id}}">
						<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'/adhibition/store/cert_edit'}"></kd-button>
					</router-link>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_store_cert_del'}"  @click="deleteCert(record.cert_id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getCertList(info.page,e)}"
				@change="(e)=>{getCertList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.template" title="请选择模板类型" :footer="null" @cancel="show.template = false" width="840px">
			<div class="cert-template">
				<router-link :to="{path:'/adhibition/store/cert_edit',query:{template_id:'personal'}}">
					<div class="cert-template-item" style="background:#409eff;">
						<span>个人认证</span>
						<img src="@/assets/img/store/geren.png" alt="">
						<img src="@/assets/img/icon-share-selected.png" class="ct-active-icon" v-if="template_id == 'personal'">
					</div>
				</router-link>
				<router-link :to="{path:'/adhibition/store/cert_edit',query:{template_id:'business'}}">
					<div class="cert-template-item" style="background: #16d2a8;">
						<span>企业认证</span>
						<img src="@/assets/img/store/qiye.png" alt="">
						<img src="@/assets/img/icon-share-selected.png" class="ct-active-icon" v-if="template_id == 'business'">
					</div>
				</router-link>
				<router-link :to="{path:'/adhibition/store/cert_edit',query:{template_id:'custom'}}">
					<div class="cert-template-item" style="background: #fd6f6e;">
						<span>自定义认证</span>
						<img src="@/assets/img/store/other.png" alt="">
						<img src="@/assets/img/icon-share-selected.png" class="ct-active-icon" v-if="template_id == 'custom'">
					</div>
				</router-link>
			</div>
		</a-modal>
	</div>
</template>

<script>
import shopModel from '@/api/addons/shop.js'
import { reactive, toRefs } from 'vue'
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			show:{ template:false },
			template_id:'',
		})
		getCertList(1,_d.info.limit)
		function getCertList(page,limit){
			shopModel.getShopCert(page,limit,res=>_d.info = {limit,...res})
		}
		const deleteCert = (id)=>shopModel.deleteShopCert(id,()=>getCertList(_d.info.page,_d.info.limit))
		return{
			...toRefs(_d),
			getCertList,
			deleteCert,
		}
	}
}
</script>

<style lang="scss">
.cert-template{
	width: 90%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: 5%;
	&-item{
		width: 226px;
		height: 100px;
		border: 1px solid #e8e6e6;
		margin-bottom: 10px;
		padding: 0 10px;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 18px;
		color: #fff;
		padding: 0 30px;
		img{
			width: 30px;
		}
	}
	.ct-active-icon{
		position: absolute;
		margin-top:68px;
		margin-left: 164px;
		font-size: 33px;
		color: #020202;
	}
}
</style>
